import React from "react";
import axios from "axios";
import moment from "moment";

class App extends React.Component {
  constructor() {
    super();
    this.state = {
      submitted: false,
    };
  }
  componentDidMount(){
    const params = window.location.search.replace("?","").split("&").reduce((j,x) => j = ({...j, [x.split("=")[0]]:x.split("=")[1]}) ,{});
    if(!params.id) window.location.href = "https://energyclaims.com";
    this.setState({companyName: params.name ? params.name : "", id: params.id});
  }
  handleSubmit = async (event) => {
    event.preventDefault();
    this.setState({ submitted: true });
    const data = {
      "template":"c384cd4d51de74c5a53241a25760da81a2eb345b",
      "signer":{"name":this.state.name,"email":this.state.email,"telephone":this.state.number},
      "title":"Letter of Authority",
      "agentEmail":"email@energyclaims.com",
      "formData":[{"name":"company_name","value":this.state.companyName},
      {"name":"company_address","value":this.state.companyAddress + ", " + this.state.companyPostcode},
      {"name":"job_title","value":this.state.title}],
      "metadata":{"clientId":this.state.id}};
    console.log(data);
    const res = await axios.post("https://api.crm.energyclaims.com/documents/web", data);
    console.log(res.data);
  }
  render() {
    return (
      <div className="container">
        <div className="col-start-2">
          <div className="colour-white bg-purple padding-61 col-1 gap-80 banner">
            <div className="margin-bottom-10 header">
              <img
                alt=""
                src="https://energy-claims.ams3.digitaloceanspaces.com/branding/logo-light.svg"
                style={{ width: "180px", textAlign:"center", margin: "0 auto", display: "block" }}
              />
            </div>
            <div className="margin-bottom-20 text-center">
              <h1 className="ui-title-mega colour-white">
              Did your energy broker disclose commission?
              </h1>
              <h2
                className="ui-title-two ui-title-sub margin-top-10"
                style={{ opacity: 1 }}
              >
                If not, you could be owed money.
              </h2>
            </div>
          </div>
          <div className="padding-60 col-1 gap-60">
            <a name="notify" href="#"></a>
            {this.state.submitted ? null : <div className="text-center" style={{marginTop: -30}}>
              <h1 className="ui-title-two">
              Get started on your claim in minutes.
              </h1>
              <h2
                className="ui-title-five ui-title-sub margin-top-15"
                style={{ opacity: 1 }}
              >
                Fill out this form and you will be sent a Letter of Authority so we can gather further information. It's quick and easy.
              </h2>
            </div>}
            {this.state.submitted ? (
              <p className="ui-label margin-top-60 text-center">
                You will recieve an email to sign the Letter of Authority in a few moments. Please do this to complete the first step in your claim.
              </p>
            ) : (
              <div
                style={{
                  display: "grid",
                  gridTemplateColumns:
                    "minmax(1vw,1fr) minmax(0,500px) minmax(1vw,1fr)",
                }}
              >
                <form
                  className="col-1 gap-20 col-start-2 margin-top-0"
                  onSubmit={this.handleSubmit}
                >
                  <div className="grid gap-10">
                    <label className="ui-label">Business Name *</label>
                    <input
                      type="text"
                      className="ui-input"
                      required
                      value={this.state.companyName}
                      onChange={(e) =>
                        this.setState({ companyName: e.currentTarget.value })
                      }
                    />
                  </div>
                  <div className="grid gap-10">
                    <label className="ui-label">Business Address *</label>
                    <textarea
                      className="ui-input"
                      required
                      onChange={(e) =>
                        this.setState({ companyAddress: e.currentTarget.value })
                      }
                      style={{height: 100}}
                    />
                  </div>
                  <div className="grid gap-10">
                    <label className="ui-label">Business Postcode *</label>
                    <input
                      type="text"
                      className="ui-input"
                      required
                      onChange={(e) =>
                        this.setState({ companyPostcode: e.currentTarget.value })
                      }
                    />
                  </div>
                  <div className="grid gap-10">
                    <label className="ui-label">Your Full Name *</label>
                    <input
                      type="text"
                      className="ui-input"
                      required
                      onChange={(e) =>
                        this.setState({ name: e.currentTarget.value })
                      }
                    />
                  </div>
                  <div className="grid gap-10">
                    <label className="ui-label">Your Job Title *</label>
                    <input
                      type="text"
                      className="ui-input"
                      required
                      onChange={(e) =>
                        this.setState({ title: e.currentTarget.value })
                      }
                    />
                  </div>
                  <div className="grid gap-10">
                    <label className="ui-label">Email Address *</label>
                    <input
                      type="email"
                      className="ui-input"
                      required
                      onChange={(e) =>
                        this.setState({ email: e.currentTarget.value })
                      }
                    />
                  </div>
                  <div className="grid gap-10">
                    <label className="ui-label">Contact Number *</label>
                    <input
                      type="text"
                      className="ui-input"
                      required
                      onChange={(e) =>
                        this.setState({
                          number: e.currentTarget.value,
                        })
                      }
                    />
                  </div>
                  <button
                    className="ui-btn-primary margin-top-20"
                    style={{ maxWidth: 200 }}
                  >
                    Submit your claim
                  </button>
                  <p className="ui-body">
                    By clicking "Submit your claim" you agree to be sent a "Letter of Authority" via email for your review and to sign. You may also be contacted by one of our agents. Learn more on our <a href="https://energyclaims.com" target="_blank">website</a>.
                  </p>
                </form>
              </div>
            )}
            <div />
            <div />
          </div>
          <div className="footer col-2 padding-60">
            <img
            alt="EnergyClaims.com"
            src="https://energy-claims.ams3.digitaloceanspaces.com/branding/logo-dark.svg"
              style={{ width: "180px" }}
            />
            <p
              className="ui-body"
              style={{ justifySelf: "flex-end", alignSelf: "center" }}
            >
              &copy; 2022 Energy Claims Limited
            </p>
          </div>
        </div>
      </div>
    );
  }
}

export default App;
